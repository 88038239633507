<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between mb-4">
          <h4>Notificações</h4>
          <b-button variant="secondary" @click="changeRouteNewAlert">
            <b-icon icon="plus" />
            NOVA NOTIFICAÇÃO
          </b-button>
        </div>
        <b-card-text class="d-flex flex-column">
          <b-table
            :items="alertDateFormat"
            :fields="fields"
            :busy="isLoading"
            bordered
            striped
            hover
          >
            <template #cell(actions)="row">
              <b-button
                variant="primary"
                size="sm"
                class="mr-2"
                @click="changeRouteEditAlert(row.item)"
              >
                <b-icon-pencil />
              </b-button>
              <b-button
                size="sm"
                variant="danger"
                @click="openRemoveAlertModal(row.item.id)"
              >
                <b-icon-trash icon="eye-slash" />
              </b-button>
            </template>
          </b-table>
          <b-button @click="getMore">
            <b-icon icon="arrow-clockwise" animation="spin" v-if="isLoading" />
            CARREGAR MAIS
          </b-button>
        </b-card-text>
      </b-col>
    </b-row>
    <custom-modal
      v-model="removeDialog"
      title="Tem certeza?"
      message="Deseja remover essa notificação."
      @confirm="deleteAlert"
    />
  </b-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import CustomModal from "../../components/shared/CustomModal.vue";

export default {
  components: { CustomModal },
  name: "listAlerts",
  data() {
    return {
      page: 2,
      removeDialog: false,
      idToRemove: null,
      fields: [
        {
          key: "titulo",
          label: "Título",
          sortable: true,
        },
        {
          key: "mensagem",
          sortable: true,
        },
        {
          key: "data",
          label: "Data expira",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          thStyle: {
            width: "120px !important",
          },
        },
      ],
    };
  },
  computed: {
    ...mapState("alert", [
      "alerts",
      "isLoading",
      "isLoadingSearch",
      "loadMore",
    ]),
    alertDateFormat() {
      return this.alerts.map((e) => ({
        ...e,
        data:
          new Date(e.data_expira).toLocaleDateString("pt-BR") +
          " " +
          new Date(e.data_expira).toLocaleTimeString("pt-BR"),
      }));
    },
  },
  methods: {
    ...mapActions("alert", ["getAllAlerts", "getMoreAllAlerts", "removeAlert"]),
    ...mapMutations("alert", ["setOneAlert", "clearAlertValues"]),

    changeRouteNewAlert() {
      this.clearAlertValues();
      this.$router.push(`notificacoes/criar`);
    },
    changeRouteEditAlert(alert) {
      this.setOneAlert(alert);
      this.$router.push(`notificacoes/${alert.id}/editar`);
    },
    openRemoveAlertModal(id) {
      this.idToRemove = id;
      this.removeDialog = true;
    },
    async getMore() {
      const { page } = this;
      if (!this.loadMore) return;
      await this.getMoreAllAlerts(page);
      this.page++;
    },
    async deleteAlert() {
      const { idToRemove } = this;
      await this.removeAlert(idToRemove);
      this.removeDialog = false;
    },
  },
  async created() {
    await this.getAllAlerts();
  },
};
</script>

<style scoped>
.lvj-search-input {
  width: 350px;
}
</style>
