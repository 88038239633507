<template>
  <b-modal
    id="modal-footer-sm"
    :title="title"
    :visible="visible"
    button-size="sm"
    centered
    @change="$emit('close', false)"
  >
    <p class="my-2">{{ message }}</p>
    <template #modal-footer>
      <b-button variant="success" class="mr-2" @click="$emit('close', false)">
        CANCELAR
      </b-button>
      <b-button variant="danger" @click="$emit('confirm')">CONFIRMAR</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  model: {
    prop: "visible",
    event: "close",
  },
  props: {
    visible: {
      type: Boolean,
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  name: "CustomModal",
};
</script>
